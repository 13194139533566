import React from 'react';
import {FormattedMessage} from 'react-intl';

import CryingPicture from 'assets/image/contact-line/helpCry.svg';
import RunningPicture from 'assets/image/contact-line/helpRun.svg';
import TreePicture from 'assets/image/contact-line/helpTree.svg';

import './styles.scss';

type Props = {
  hideSeparator?: boolean;
}

export const ContactLine = ({hideSeparator}: Props): JSX.Element => {
  const separator = hideSeparator ? null : (<div className="contact-line__border"/>);

  return <div className="contact-line">
    {separator}
    <div className="container">
      <div className="contact-line__info">
        {/* <img src={CryingPicture} className="contact-line__info__cry-picture" alt="" role="presentation"/>*/}
        <div className="contact-line__info__numbers">
          <div className="contact-line__info__numbers__life-line">
            <div className="contact-line__info__text">
              {'If you\'re experiencing'}
            </div>
            <div className="contact-line__info__text">
              <div className="contact-line__info__numbers__bold">
                {'a crisis or feeling unsafe,'}
              </div>
            </div>
            <div className="contact-line__info__text">
              {'please call'}
              <div className="contact-line__info__numbers__number">
                {'000'}
              </div>
              <div className="contact-line__info__numbers__bold">
                {'(available 24 hours)'}
              </div>
            </div>
            <div className="contact-line__info__text">
              {'or Lifeline on'}
              <div className="contact-line__info__numbers__number">
                {'13 11 14'}
              </div>
              <div className="contact-line__info__numbers__bold">
                {'(available 24 hours)'}
              </div>
            </div>
          </div>
          <div className="contact-line__info__numbers__life-line">
            <div className="contact-line__info__text">
              {'South Australia Mental Health crisis line'}
            </div>
            <div className="contact-line__info__numbers__number">
              {'131 465'}
            </div>
            <div className="contact-line__info__numbers__bold">
              {'(available 24 hours)'}
            </div>
          </div>
          <div className="contact-line__info__numbers__life-line">
            <div className="contact-line__info__text">
              {'Beyond Blue'}
            </div>
            <div className="contact-line__info__numbers__number">
              {'1300 22 4636'}
            </div>
            <div className="contact-line__info__numbers__bold">
              {'(available 24 hours)'}
            </div>
          </div>
          <div className="contact-line__info__numbers__life-line">
            <div className="contact-line__info__text">
              {'Gambling Helpline'}
            </div>
            <div className="contact-line__info__numbers__number">
              {'1800 858 858'}
            </div>
            <div className="contact-line__info__numbers__bold">
              {'(available 24 hours)'}
            </div>
          </div>
          <div className="contact-line__info__numbers__life-line">
            <div className="contact-line__info__text">
              {'Gamblers Anonymous Australia'}
            </div>
            <div className="contact-line__info__numbers__number">
              {'(08) 8212 6933'}
            </div>
          </div>
          <div className="contact-line__info__numbers__life-line">
            <div className="contact-line__info__text">
              {'National Domestic Family and Sexual Violence Counselling Service'}
            </div>
            <div className="contact-line__info__numbers__number">
              {'1800 737 737'}
            </div>
          </div>
        </div>
        {/* <section className="contact-line__info__pictures">*/}
        {/*  <img src={RunningPicture} className="contact-line__info__pictures__run" alt="" role="presentation"/>*/}
        {/*  <img src={TreePicture} className="contact-line__info__pictures__tree" alt="" role="presentation"/>*/}
        {/* </section>*/}
      </div>
    </div>
    {/* <div className="contact-line__title">*/}
    {/*  <div className="container container__wrapper">*/}
    {/*    <div className="contact-line__title__text">*/}
    {/*      <FormattedMessage*/}
    {/*        id={'gritx.footer.contact.title'}*/}
    {/*        defaultMessage={'CONTACT US'}*/}
    {/*      />*/}
    {/*    </div>*/}
    {/*  </div>*/}
    {/* </div>*/}
  </div>;
};
